@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");

html {
  overflow: hidden;
  height: 100%;
}

#root {
  font-family: "PT Sans", sans-serif;
}

.pt-sans-regular {
  font-family: "PT Sans", "regular" !important;
}

::-webkit-scrollbar {
  /* display: none; */
  /* blocking the vertical-side-bar of the table so commenting the code */
}

@font-face {
  font-family: "PT Sans";
  src: url();
}

#root {
  height: 100%;
}

:root {
  --clr-primary: #000000;
  --clr-primary-light: #333;
  --clr-light-dark: #00000026;
  --clr-secondary: #adadad;
  --clr-primary-lighter: #ecebf6;
  --clr-red: #bd3432;
  --clr-red-autumn: #712925;
  --clr-violet: rgb(52, 55, 158);
  --clr-green: rgb(0, 179, 0);
  --clr-white: #ffffff;
  --clr-blue: #605cc7;
  --clr-darkblue: #292b6b;
  --clr-gray-lighter: #e5e5e5;
  --clr-gray-light: rgb(186, 186, 186);
  --clr-grayish: #d2d2d2;
  --clr-gray-darkest: #7c7c7c;
  --clr-light-black: rgba(0, 0, 0, 0.15);
  --clr-gray: #efefef;
  --clr-dark-blue: #292b6b;
  --clr-wheat: #c9c9c9;
  --clr-blue-light: #ecebf6;
}

.w-25 {
  width: 25%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.w-100 {
  width: 100%;
}

.text_color_primary {
  color: var(--clr-blue);
}

.d-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.text-wrap-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-overflow-multiple-line {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pr-2 {
  padding-right: 1rem;
}

[type="color"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="url"],
[type="week"],
[type="time"],
select,
textarea {
  display: block;
  border-radius: 0.188rem;
  padding: 0.313rem 0.625rem;
  margin: 0rem;
  outline: none;
  border-width: 0.063rem;
  border-style: solid;
  width: 100%;
  max-width: 100%;
  line-height: 2;
  height: 3rem;
  border-color: var(--clr-gray-lighter) !important;
  appearance: none;
  background-color: var(--clr-white);
  color: var(--clr-gray-darkest);
}

input[type="file"] {
  width: 100%;
  margin: 0;
  padding: 2px;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  border-width: 1px;
  border-color: var(--clr-gray);
  border-style: solid;
  height: 42px;
  background-color: var(--clr-white);
}

svg.deleteIcon {
  display: block;
  width: 15px;
  height: 20px;
  overflow: hidden;
  margin-left: 7px;
  margin-top: 2px;
}

.svgBg {
  position: absolute;
  right: 0.063rem;
  top: 0.063rem;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}

.single-select {
  position: relative;
  margin: 0rem;
  padding: 0rem;
  overflow: hidden;
}

.flyoutRight__head .close svg {
  width: 1.438rem;
}

svg.clearIcon {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  overflow: hidden;
}

svg.clearIcon .cls-1 {
  fill: var(--clr-blue);
  fill-rule: evenodd;
}

svg.clearIcon .cls-1 .cls-2 {
  fill: var(--clr-blue);
  fill-rule: evenodd;
}

.btn {
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  border-radius: 0.25rem;
  border-width: 0.063rem;
  border-style: solid;
  border-color: var(--clr-blue);
  font-weight: 400;
  font-size: 1rem;
  padding: 0.625rem 1.25rem;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  line-height: 1;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.btn--md {
  font-size: 0.875rem;
  padding: 0.75rem 1.375rem;
}

.btn.btn--rounded {
  border-radius: 1.875rem;
}

.btn.btn--alt {
  color: var(--clr-blue);
  background-color: var(--clr-white);
  border-color: var(--clr-gray);
}

.btn.btn--alt:hover {
  background-color: var(--clr-darkblue);
  color: var(--clr-white);
}

.button-primary {
  background: var(--clr-blue) !important;
  color: var(--clr-white) !important;
}

.button-primary:hover {
  background: var(--clr-gray-darkest) !important;
}

.field-group_label {
  display: flex;
  align-items: center;
  margin-bottom: 0.188rem;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: var(--clr-gray-darkest);
}

.field-radio-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.25rem 0.5rem;
}

.field-radio-group > label {
  display: inline-flex;
  align-items: center;
  flex: auto;
}

input[type="radio"]:checked {
  background-color: var(--clr-white);
  border-color: var(--clr-blue);
}

input[type="checkbox"],
input[type="radio"] {
  height: 20px !important;
  width: 20px !important;
  position: relative !important;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  border-style: solid;
  cursor: pointer;
  font: inherit;
  margin-right: 5px;
  border-width: 2px;
  outline: none;
  vertical-align: sub;
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"]:checked:before {
  position: absolute !important;
  width: 8px !important;
  height: 8px !important;
  top: 4px !important;
  left: 4px !important;
}

input[type="radio"]:checked:before {
  content: "";
  width: 14px;
  height: 14px;
  top: 3px;
  left: 3px;
  position: absolute;
  z-index: 2;
  display: block;
  border-radius: 100%;
}

svg.iconBack {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

svg.iconBack .cls-1 {
  fill: transparent;
}

svg.iconBack .cls-2,
svg.iconBack .cls-3 {
  fill: none;
  stroke: var(--clr-primary);
  stroke-linecap: round;
  stroke-width: 2px;
}

svg.iconBack .cls-2 {
  stroke-linejoin: round;
}

input[type="radio"] {
  border-radius: 50%;
}

.topRowHolder {
  position: sticky;
  z-index: 0;
  display: block;
  top: 0px;
  margin: 0px;
  padding: 0px;
  height: 80px;
  border-bottom: 1px solid var(--clr-gray-light);
  background-color: var(--clr-white);
}

.headerLevelTwo {
  display: block;
  margin: 0px;
  padding: 19px 20px 18px 20px;
}

.headerLevelTwo .header4Cols {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.headerLevelTwo .header4Cols .menuCol {
  width: 42px;
  cursor: pointer;
}

.headCol {
  width: calc(100% - 100px);
  margin-left: 20px;
}

.img-circle {
  border-radius: 50%;
}

.w-20 {
  width: 20%;
}

.m-4 {
  margin: 2rem;
}

.p-4 {
  padding: 2rem;
}

.pl-2 {
  padding-left: 1rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.ml-3 {
  margin-left: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-start {
  justify-content: flex-start;
}

.field-group .previewArea {
  display: block;
  margin: 4px 0px 0px 0px;
  padding: 0px;
}

.field-group .previewArea .eachFile {
  position: relative;
  display: block;
  margin: 5px 0px;
  padding: 8px;
  overflow: hidden;
  border: 1px solid var(--clr-gray);
  border-radius: 3px;
  width: fit-content;
}

.field-group .previewArea .removeFile {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid var(--clr-blue);
  border-radius: 18px;
  z-index: 2;
  background-color: var(--clr-white);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 17px;
  font-weight: bold;
  color: var(--clr-blue);
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
}

.field-group .dropArea {
  display: block;
  margin: 0px;
  padding: 10px 10px 20px 10px !important;
  background-color: var(--clr-primary-lighter);
  border-radius: 3px;
  border: 1px solid var(--clr-blue);
  overflow: hidden;
}

.field-group .dropArea button {
  display: block;
  margin: 0px auto;
  padding: 6px 20px;
  background-color: var(--clr-blue);
  border-radius: 6px;
  overflow: hidden;
  color: var(--clr-white);
  font-size: 14px;
  line-height: 18px;
  border: 1px solid var(--clr-blue);
}

.field-group .dropArea p {
  display: block;
  margin: 0px auto;
  padding: 7px 0px;
  font-size: 14px;
  line-height: 18px;
  color: var(--clr-primary);
  text-align: center;
}

.field-group .field__text {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: var(--clr-gray-darkest);
  margin-bottom: 4px;
}

.flyoutRight__footer {
  padding: 15px 5px;
  border-top: 1px solid var(--clr-gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: calc(100% - 40px);
  bottom: 0;
  left: 0;
  margin: 0px 20px;
}

.flyoutRight__footer::before {
  content: none;
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 1px;
  background-color: var(--clr-gray);
}

.flyoutRight__body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1rem;
}

.required-asterisk {
  color: var(--clr-red);
  margin-left: 2px;
}

.field-group_label .hint {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.field-group_label .hint svg {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.field-group_label .hint-info {
  position: absolute;
  color: var(--clr-white);
  text-transform: none;
  background-color: var(--clr-darkblue);
  width: 150px;
  text-align: center;
  padding: 5px;
  border-radius: 8px;
  left: -68px;
  top: calc(100% + 5px);
  line-height: 1.3;
  z-index: 2;
  display: none;
}

.field-group_label .hint-info:after {
  top: -10px;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(41, 43, 107, 0);
  border-bottom-color: var(--clr-darkblue);
  border-width: 6px;
  margin-left: -7px;
}

.field-group_label .hint.show .hint-info {
  display: block;
}

.field-group + .field-group {
  margin-top: 1px;
}

.flyoutRight-block {
  padding: 10px 0;
  line-height: 1.25;
}

svg.iconFieldHelp .cls-1 {
  fill: var(--clr-blue);
}

.etcol,
.etcol-1,
.etcol-10,
.etcol-11,
.etcol-12,
.etcol-2,
.etcol-3,
.etcol-4,
.etcol-5,
.etcol-6,
.etcol-7,
.etcol-8,
.etcol-9 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.etcol {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.etrow {
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

@media (min-width: 768px) {
  .etrow > [class*="etcol-"] {
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .etrow .etcol-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .etrow .etcol-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .etrow .etcol-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .etrow .etcol-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .etrow .etcol-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .etrow .etcol-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .etrow .etcol-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .etrow .etcol-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .etrow .etcol-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .etrow .etcol-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .etrow .etcol-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .etrow .etcol-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

h2 {
  font-size: 22px;
  font-weight: 700;
}

h2.header-title {
  color: var(--clr-gray-darkest);
  margin-bottom: 10px;
  font-weight: 700;
}

svg.etLogo42 {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

svg.etLogo42 .cls-1 {
  fill: #bd3432;
}

svg.etLogo42 .cls-2 {
  fill: #fbf8f9;
}

svg.etLogo42 .cls-3 {
  fill: #712925;
}

svg.etLogo42 .cls-4 {
  fill: #fefefe;
}

.field-group:not(:last-child) > div {
  padding-bottom: 10px;
}

.field-group .has-icon {
  position: relative;
}

.field-group .has-icon .field-group_field {
  padding-right: 40px;
}

.field-group .has-icon .icon {
  position: absolute;
  right: 10px;
  top: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.field-group .has-icon .icon svg {
  width: 20px;
}

.pgStructureOne {
  display: block;
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  height: 100vh;
}

.pgStructureOne .topRowHolder {
  position: sticky;
  z-index: 5;
  display: block;
  top: 0px;
  margin: 0px;
  padding: 0px;
  height: 80px;
  border-bottom: 1px solid var(--clr-gray-light);
}

.pgStructureOne .btmRowHolder {
  position: relative;
  display: block;
  margin: 0px;
  padding: 0px;
  height: 100px;
  border-top: 1px solid var(--clr-gray-light);
}

.pgStructureOne .contentRowHolder {
  position: relative;
  display: block;
  padding: 0px;
  height: calc(100% - 180px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media all and (max-width: 767px) {
  .pgStructureOne .topRowHolder {
    height: 105px;
  }

  .pgStructureOne .btmRowHolder {
    height: 80px;
  }

  .pgStructureOne .contentRowHolder {
    height: calc(100% - 184px);
  }
}

.text-align-left {
  text-align: left;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: var(--clr-white);
  border-width: 1px;
  border-color: var(--clr-gray);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  padding: 10px 20px;
  border-radius: 6px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin: -1rem -1rem 0 -1rem;
}

.center-align-card {
  margin: 4rem auto;
  width: 30% !important;
  height: fit-content !important;
  padding: 4rem 2rem !important;
}

.center-align-card h2 {
  color: var(--clr-darkblue);
}

.card .card__body {
  flex: 1 1 auto;
  padding-bottom: 10px;
}

.text-align-left {
  text-align: left;
}

input:disabled,
select:disabled,
textarea:disabled {
  background-color: var(--clr-gray);
  cursor: not-allowed;
}

.icon {
  display: inline-flex;
}

@media (max-width: 767px) {
  .pgStructureOne .topRowHolder {
    height: 110px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo {
    padding: 15px 15px 15px 15px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol {
    order: 2;
    width: calc(100% - 32px) !important;
    direction: rtl;
  }
}

.headerLevelTwo .header4Cols .icnsCol {
  width: 156px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .menuCol {
    order: 1;
    width: 32px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .headCol {
    order: 3;
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons {
  display: flex;
  margin: 0px;
  padding: 0px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons {
    width: 126px;
    direction: ltr;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon:first-child {
  margin-left: 0px;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon {
  cursor: pointer;
  width: 42px;
  height: 42px;
  margin-left: 15px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon {
    width: 32px;
    height: 32px;
  }
}

svg.IconNotification {
  display: block;
  margin: 0px;
  padding: 0px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

svg.IconNotification .cls-1 {
  fill: transparent;
}

svg.IconNotification.unread .cls-2 {
  fill: var(--clr-red);
}

.dropdown {
  position: relative;
  border: none;
}

a {
  color: var(--clr-blue);
  text-decoration: none;
  background-color: transparent;
}

.profileImgHolder {
  position: relative;
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 21px;
  background-color: var(--clr-purple);
  overflow: hidden;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder img {
  position: absolute;
  display: block;
  left: 0px;
  right: 0px;
  width: 42px;
  height: 42px;
  -o-object-fit: cover;
  object-fit: cover;
  z-index: 1;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon .profileImgHolder img {
    width: 32px;
    height: 32px;
  }
}

.profile-img {
  height: 120px;
  max-width: 100% !important;
  object-fit: contain;
}

.headerLevelTwo
  .header4Cols
  .icnsCol
  .allIcons
  .icon
  .profileImgHolder
  svg.profileImgHover {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
}

@media (max-width: 767px) {
  .headerLevelTwo
    .header4Cols
    .icnsCol
    .allIcons
    .icon
    .profileImgHolder
    svg.profileImgHover {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

.profileImgHolder .profile-arrow-svg {
  bottom: 1px !important;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown .dropdown-menu {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: var(--clr-gray-darkest);
  text-align: left;
  list-style: none;
  background-color: var(--clr-white);
  background-clip: padding-box;
  border: 1px solid var(--clr-secondary);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 6px;
  display: none;
}

.dropdown .dropdown-menu.dropdown-menu-right {
  right: 0;
  left: auto;
}

.headerLevelTwo
  .header4Cols
  .icnsCol
  .allIcons
  .icon
  .profileImgHolder:hover
  svg.profileImgHover {
  display: block;
}

.dropdown.drop-hover .dropdown-menu {
  display: block;
}

.dropdown .dropdown-menu .dropdown-divider {
  height: 0;
  margin: 0 0;
  overflow: hidden;
  border-top: 1px solid var(--clr-gray);
  display: block;
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.dropdown .dropdown-menu .dropdown-item {
  display: block;
  width: 100%;
  padding: 5px 15px;
  clear: both;
  font-weight: normal;
  color: var(--clr-blue);
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
  height: 37px;
}

@media (max-width: 767px) {
  .dropdown .dropdown-menu .dropdown-item {
    height: 45px;
  }
}

.headerLevelTwo .header4Cols .icnsCol .allIcons .dropdown-item svg {
  width: 15px;
}

@media (max-width: 767px) {
  .headerLevelTwo .header4Cols .icnsCol .allIcons .icon svg {
    width: 32px;
    height: 32px;
  }
}

.dropdown .dropdown-menu .dropdown-item:focus,
.dropdown .dropdown-menu .dropdown-item:hover {
  color: var(--clr-secondary);
  text-decoration: none;
  background-color: var(--clr-blue-light);
}

.dropdown .dropdown-menu .dropdown-item.active,
.dropdown .dropdown-menu .dropdown-item:active {
  color: var(--clr-white);
  text-decoration: none;
  background-color: var(--clr-blue);
}

.dropdown .dropdown-menu .dropdown-item.disabled,
.dropdown .dropdown-menu .dropdown-item:disabled {
  color: var(--clr-gray);
  background-color: transparent;
}

.dropdown .dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.dropdown .dropdown-menu .dropdown-item:last-child {
  padding-bottom: 0.5rem;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.dropdown .dropdown-menu:after,
.dropdown .dropdown-menu:before {
  bottom: 100%;
  left: calc(100% - 20px);
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.dropdown .dropdown-menu:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: var(--clr-white);
  border-width: 6px;
  margin-left: -6px;
}

.dropdown .dropdown-menu:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: var(--clr-secondary);
  border-width: 7px;
  margin-left: -7px;
}

.btn--icon {
  display: flex !important;
  color: var(--clr-primary) !important;
  background-color: var(--clr-white) !important;
  border-color: var(--clr-gray) !important;
  padding: 8px !important;
  align-items: center;
  justify-content: center;
}

.word-break-all {
  word-break: break-all;
}

img {
  object-fit: contain;
  max-width: calc(100% - 25px);
}

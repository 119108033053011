.nav-profile-pic {
  position: absolute !important;
  display: block !important;
  left: 0px !important;
  right: 0px !important;
  width: 42px !important;
  height: 42px !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  z-index: 1 !important;
}

.profile-initials {
  width: 100% !important;
  height: 100% !important;
}

.profileImgHolder .profile-arrow-svg {
  bottom: 1px !important;
}

/* notification css */

.notification-list-header {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #605cc7;
  box-shadow: 0px 6px 10px 0px rgb(0 0 0 / 36%);
  display: flex;
  justify-content: space-between;
}

.notification-list-parent {
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 280px;
  width: 330px;
  overflow-y: auto;
}

.notification-list-childs {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem !important;
}

.notification-list-child {
  cursor: pointer;
}

.notification-list-border {
  border-bottom: 1px solid #dee2e6 !important;
}

.notification-list-child-unread {
  background-color: var(--clr-gray-light);
}

.notification-list-child-header {
  font-size: 0.8rem;
  font-weight: 500;
}

.notification-list-header-span {
  color: white;
  padding-left: 0.5rem !important;
  font-weight: 700;
}

.notification-list-header-mark-span {
  color: white;
  font-size: 12px;
  padding-right: 0.5rem !important;
  cursor: pointer;
}

.notification-mark-resolved-button {
  padding: 0.4rem !important;
}

.notification-list-loader {
  height: 50px;
  margin-left: 155px;
}

.notification-custom-popover {
  border-radius: "2px";
  overflow: "hidden";
}

.ant-popover-content {
  border-radius: 10px;
}

/* Adjusting the arrow to match the rounded corners */
.ant-popover-inner::before {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notification-popover-container {
  border-radius: 16px !important;
}

.ant-popover-inner {
  padding: 0 !important;
}

.notification-list-child-span {
  font-size: 0.7rem;
}

.notification-tab-container {
  display: flex;
  border-bottom: 1px solid #dee2e6;
}

.notification-tab-content {
  color: #605cc7;
  padding: 12px 20px;
  width: fit-content;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
}

.notification-active-tab-content {
  border-bottom: 3px solid #292b6b;
}

.notification-bell-icon {
  font-size: 28px;
}

.custom-popover-content {
  padding: 0px 0px;
}

.ant-popover-arrow {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.notification-list-parent::-webkit-scrollbar {
  width: 12px;
  background-color: var(--clr-gray-lighter);
  border-left: 1px solid transparent;
  height: 12px;
}

.notification-list-parent::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: var(--clr-blue);
  border: 0px solid var(--clr-blue);
  border-right: 0px solid var(--clr-blue);
  max-width: 230px;
  max-height: 230px;
}
